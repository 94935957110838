#banner {
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  object-fit: cover;
  object-position: center;
}
#banner span {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #e74c3c;
  opacity: 0.5;
}
#banner h1 {
  text-shadow: 0px 2px #000;
}
