@import "~react-image-gallery/styles/css/image-gallery.css";
#hero {
  max-height: calc(100vh - 167px) !important;
}
.hero-slider {
  max-height: calc(100vh - 147px) !important;
  overflow: hidden;
}
#hero .translate-middle {
  background-color: #00000075 !important;
  font-size: 1.5em !important;
}
#hero img {
  width: 100%;
  height: 575px;
  object-fit: cover;
  object-position: center;
}
.image-gallery-image {
  object-fit: cover !important;
}
#hero h3 {
  text-shadow: 2px 2px #2c3e50;
}
#about img {
  max-height: 300px;
  border-radius: 25px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  -ms-border-radius: 25px;
  -o-border-radius: 25px;
}
#about p {
  text-align: right;
}
#services .service-card,
#services h3,
#services i {
  transition: 0.1s 0s linear;
  -webkit-transition: 0.1s 0s linear;
  -moz-transition: 0.1s 0s linear;
  -ms-transition: 0.1s 0s linear;
  -o-transition: 0.1s 0s linear;
}
#services .service-card:hover {
  border-color: #e74c3c !important;
  transform: translateY(-10px);
  -webkit-transform: translateY(-10px);
  -moz-transform: translateY(-10px);
  -ms-transform: translateY(-10px);
  -o-transform: translateY(-10px);
}
#services .service-card:hover h3 {
  color: #e74c3c;
  cursor: pointer;
}
#services .service-card:hover i {
  color: #2c3e50;
  opacity: 0.5;
}
#usa-process .flex-row-reverse:hover > p {
  color: #e74c3c;
  transition: 0.1s 0s linear;
}
#contact img {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
}
#contact .buttons,
#contact .text {
  z-index: 5;
}
.accordion {
  max-width: 700px;
  margin: 0 auto;
}
