:root {
  --main-color: #e74c3c
  --secondary-color: #2c3e50
}
* {
  box-sizing: border-box;
}
a {
  text-decoration: none;
  color: inherit;
}
ul li {
  text-decoration: none;
  list-style: none;

}
.bg {
 z-index: -5;
}
@tailwind base;
@tailwind components;
@tailwind utilities;

.main-color {
  color: #e74c3c;
}
.secondary-color {
  color: #2c3e50;
}
.main-background {
  background-color: #e74c3c;
}
.secondary-background {
  background-color: #2c3e50;
}
.main-hover:hover {
  color: #e74c3c;
}