header {
  background-color: var(--main-color);
}
header .main-logo {
  height: 80px !important;
}
.nav-link {
  font-weight: bold;
}
.nav-link:active,
.nav-link:focus {
  color: var(--secondary-color) !important;
}
header i:hover {
  color: var(--secondary-color);
}
.dropdown-menu {
  min-width: auto !important;
}

#navbarScrollingDropdown {
  width: fit-content;
}
